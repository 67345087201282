body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main_container {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}


.home_container {
  position: relative;
  background-image: url('/public/img/vertical_3.jpg');
  background-size: cover;
  width: 100%;
  height: 100vh;
}

@media screen and (min-width:800px) {
  .home_container {
    background-image: url('/public/img/bg_3.jpg');
  }
}

.home_opacity_bg {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: rgb(255, 255, 255, 0.7);
}

.home_content {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}


.home_heading {
  display: flex;
  align-items: center;
  gap: 40px;
}


.home_heading_logo {
  width: 100px;
  height: 100px;
}

.home_heading_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  min-width: 200px;
}

@media screen and (min-width:800px) {
  .home_heading_info {
    flex-direction: row;
    min-width: 400px;

  }
}

.home_heading_info_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

@media screen and (min-width:800px) {
  .home_heading_info_text {
    align-items: flex-start;
  }
}

.home_heading_info_text_cn {
  font-size: 5vw;
  font-weight: bold;
  margin: 0px;
}

@media screen and (min-width:800px) {
  .home_heading_info_text_cn {
    font-size: x-large;
  }
}

.home_heading_info_text_en {
  font-size: large;
  margin: 0px;
  text-align: center;
}

.home_heading_front_img {
  display: none;
  width: 30vw;
  height: 20vw;
  border-radius: 3vw;
}

@media screen and (min-width:800px) {
  .home_heading_front_img {
    display: block;
    /*tmp*/
    /* background-image: url('/public/img/bg_4.webp'); */
    /* background-image: url('/public/img/bg_3.jpg'); */
    /* background-color: rgb(255, 255, 255, 0.4); */
  }
}

.heading_block {
  position: absolute;
  background-color: green;
  padding: 30px;
  font-size: x-large;
}

.invitation_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.invitation_input_container {
  width: 100%;
  padding: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.invitation_input {
  width: 40vw;
}

@media screen and (min-width:800px) {
  .invitation_input {
    width: 40vw;
  }
}
.invitation_button {
  font-weight: bold;
}

.invitation_error {
  color: rgb(55, 55, 55);
  font-weight: 600;
  margin: 0;
  padding-left: 5vw;
  padding-right: 5vw;
  word-break: break-all;
}